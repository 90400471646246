:root {
  --main-bg-color: #95c11f;
  --primary-color: #50066a;
  --secondary-color: #ffc107;
}

html,
body {
  margin: 0;
  padding: 0;
}
body {
  overflow-y: scroll;
  background: var(--main-bg-color)
    url('https://educatiepentrustiinta.ro/images/cest-pattern.2x.png') !important;
  font-family: sans-serif;
  user-select: none;
  cursor: default;
}
a:not([class*="MuiButtonBase"]), a:link:not([class*="MuiButtonBase"]), a:visited:not([class*="MuiButtonBase"]) {
  color:var(--primary-color);
}